import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, styled, Button, useMediaQuery } from '@mui/material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

// Define CustomTabs and CustomTab based on the styling provided in your Home component
const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#FFFFFF',
  },
});

const CustomTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontFamily: theme.typography.fontFamily,
  '&.Mui-selected': {
    color: '#000',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(255, 76, 21, 0.32)',
  },
}));

const AccountSettings = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({ email: '', firstName: '', lastName: '' });
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserDetails({
            email: user.email,
            firstName: userDoc.data().firstName,
            lastName: userDoc.data().lastName,
          });
        } else {
          console.log("No user data found in Firestore");
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <Box sx={{ padding: isMobile ? 2 : 4 }}>
      <h1 style={{ marginBottom: '20px' }}>Account</h1>
      <p style={{ marginBottom: '20px' }}>Manage your account information and preferences.</p>

      <Box sx={{ marginBottom: '30px' }}>
        <Box
          sx={{
            padding: 2,
            borderRadius: '10px',
            bgcolor: '#F5F5F5',
            marginBottom: '10px',
          }}
        >
          <p>Email: {userDetails.email}</p>
        </Box>

        <Box
          sx={{
            padding: 2,
            borderRadius: '10px',
            bgcolor: '#F5F5F5',
            marginBottom: '10px',
          }}
        >
          <p>First Name: {userDetails.firstName}</p>
        </Box>

        <Box
          sx={{
            padding: 2,
            borderRadius: '10px',
            bgcolor: '#F5F5F5',
          }}
        >
          <p>Last Name: {userDetails.lastName}</p>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column' : 'row',
          width: isMobile ? '100%' : '400px',
        }}
      >
        <Button
          aria-label="sign out"
          onClick={handleSignOut}
          sx={{
            minWidth: '80px',
            backgroundColor: 'transparent',
            color: '#FF001B',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            borderRadius: '10px',
            padding: '10px',
            height: '100%',
            marginBottom: isMobile ? '10px' : 0,
          }}
          startIcon={<LogoutRoundedIcon />}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

function Settings() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        minHeight: '100vh', // Ensure it takes at least the full viewport height
        padding: 2,
      }}
    >
      <Box sx={{ width: '100%', maxWidth: '500px' }}>
        <AccountSettings />
      </Box>
    </Box>
  );
}

export default Settings;
