import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Designs from './pages/Designs';
import Settings from './pages/Settings';
import Sidebar from './components/Sidebar'; // Adjust the path as necessary
import LoginPage from './pages/Login';
import SignupPage from './pages/SignUp';
import Welcome from './pages/Welcome';
import SavedPage from './pages/Saved';
import MockupsPage from './pages/MockupsPage';
import PublishPage from './pages/PublishPage';
import ResetPassword from './pages/ResetPassword';
import Stores from './pages/Stores';
import Orders from './pages/Orders';
import EtsyListingPage from './pages/EtsyListingPage';
import RealTimeAreaChart from './pages/Sales';
import ListingPage from './pages/ListingPage';
import Policies from './components/Ebay_Auth_Flow/Policies';
import OrderFulfillment from './components/Ebay_Auth_Flow/OrderFulfillment';
import Recommendations from './pages/Recommendations';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import Discover from './pages/Discover';
import ProductPage from './pages/ProductPage';
import Generate from './pages/Generate';
import Test from './pages/Test';

import './App.css';

// Import Chip and Box components from MUI
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

const drawerWidth = 220; // Define drawerWidth with margin

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [isOpen, setIsOpen] = useState(false); // Default state to open

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleToggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Router>
      
      <AppContent isOpen={isOpen} handleToggleDrawer={handleToggleDrawer} />
    </Router>
  );
}

function AppContent({ isOpen, handleToggleDrawer }) {
  const location = useLocation();

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {!["/welcome", "/reset", "/summary"].includes(location.pathname) && (
        <Sidebar isOpen={isOpen} handleToggleDrawer={handleToggleDrawer} />
      )}
      <Box sx={{
        flex: 1,
        transition: 'margin-left 0.3s ease-in-out',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        boxSizing: 'border-box',
        padding: 0,
      }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<ProductPage />}/>
          <Route path="/generate" element={<Generate />}/>
          <Route path="/settings" element={<Settings />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/get_mockups" element={<Test />} />

          {/* <Route path="/orders" element={<Orders />} /> */}
          {/* <Route path="/home" element={<Recommendations />} /> */}
          {/* <Route path="/summary" element={<ListingPage />} /> */}
          {/* <Route path="/signup" element={<SignupPage />} /> */}
          {/*<Route path="/" element={<LoginPage />} />*/}
          {/* <Route path="/saved" element={<SavedPage />}/> */}
          {/* <Route path="/mockups" element={<MockupsPage />} /> */}
          {/* <Route path="/designs" element={<Designs />}/> */}
          {/*  <Route path="/etsy" element={<EtsyListingPage />}/> */}
          {/*  <Route path="/sales" element={<RealTimeAreaChart />}/> */}
          {/*  <Route path="/stores" element={<Stores />}/> */}
          {/* <Route path="/stores" element={<Stores />}/> */}
          {/* <Route path="/ebay-policies-setup" element={<Policies />} /> */}
          {/* <Route path="/ebay-order-fulfillment-setup" element={<OrderFulfillment />} /> */}
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
