import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, TextField, IconButton, Skeleton, Tooltip, tooltipClasses, useMediaQuery, Typography, createTheme, ThemeProvider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { doc, onSnapshot, getDoc, collection, query, orderBy, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { db } from '../firebase'; // Ensure this is the correct path to your firebase setup
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import LightbulbIcon from '@mui/icons-material/Lightbulb'; // Import the Lightbulb icon from MUI
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import IdeasComponent from '../components/IdeasComponent';
import LoadingBox from '../components/LoadingBox'; // Ensure the correct path


const theme = createTheme();

const PlaceholderImage = styled('div')(({ theme }) => ({
  minWidth: '300px',
  maxWidth: '600px',
  width: '100%',
  borderRadius: '10px',
  border: '1px solid #F5F5F5',
  position: 'relative',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    '& .design-overlay': {
      opacity: 1,
    },
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '100%',
    borderRadius: '10px',
  },
}));

const DesignOverlay = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '20px',
  objectFit: 'cover',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  backgroundColor: '#FFFFFF'
});

const InfoBox = styled('div')({
  position: 'absolute',
  border: '1px solid #F5F5F5',
  bottom: 0,
  width: '100%',
  height: '15%',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
});

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '1rem',
    padding: '10px',
    backgroundColor: '#000000',
    fontWeight: 'bold'
  },
}));

const Recommendations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [images, setImages] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const gridRef = useRef(null);
  const [ideas, setIdeas] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showIdeasComponent, setShowIdeasComponent] = useState(true);
  const [designs, setDesigns] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isFinalLoading, setIsFinalLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Interpreting prompt...');




  const initializeIdeas = () => {
    if (location.state?.recommendations && Array.isArray(location.state.recommendations)) {
      setIdeas(location.state.recommendations.map(r => r.prompt));
    }
  };

  useEffect(() => {
    initializeIdeas();
  }, [location.state]);

  const fetchUserData = async (user) => {
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      setUserId(user.uid);
      const userData = userDoc.data();
      if (userData.ideas && userData.ideas.recommendations && Array.isArray(userData.ideas.recommendations)) {
        setIdeas(userData.ideas.recommendations.map(r => r.prompt));
      }
    } else {
      console.log("No user data found in Firestore");
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        console.log("No user is signed in.");
        setImages([]);
      }
    });
  }, []);

  useEffect(() => {
    let unsubscribeSnapshot;
    let unsubscribeHistorySnapshot;

    if (userId) {
      console.log('Setting up Firestore listener for user:', userId);
      const userRef = doc(db, 'users', userId);
      unsubscribeSnapshot = onSnapshot(userRef, (doc) => {
        const userData = doc.data();
        console.log('Received Firestore document:', userData);
        if (userData.ideas && userData.ideas.recommendations && Array.isArray(userData.ideas.recommendations)) {
          setIdeas(userData.ideas.recommendations.map(r => r.prompt));
          setLoading(false);
        } else {
          setImages([]);
          setLoading(false);
        }
      }, (error) => {
        console.error("Error fetching real-time updates:", error);
        setLoading(false);
      });

      // Setup Firestore listener for user's history collection
      const historyRef = collection(db, 'users', userId, 'history');
      const q = query(historyRef, orderBy('date_created', 'desc')); // Order by date_created in descending order
      unsubscribeHistorySnapshot = onSnapshot(q, (querySnapshot) => {
        const historyData = querySnapshot.docs.map(doc => ({ id: doc.id, mockup_url: doc.data().mockup_url, design_url: doc.data().design_url, ...doc.data() }));
        console.log('Received Firestore history collection:', historyData);
        // Filter out empty or invalid entries
        const validHistoryData = historyData.filter(img => img.mockup_url && img.design_url);
        setHistoryData(validHistoryData);
        setShowIdeasComponent(validHistoryData.length === 0);
      }, (error) => {
        console.error("Error fetching real-time history updates:", error);
      });
    }

    return () => {
      if (unsubscribeSnapshot) {
        console.log('Cleaning up Firestore listener');
        unsubscribeSnapshot(); // Cleanup the subscription when the component unmounts or user changes
      }
      if (unsubscribeHistorySnapshot) {
        console.log('Cleaning up Firestore history listener');
        unsubscribeHistorySnapshot(); // Cleanup the subscription for history when the component unmounts or user changes
      }
    };
  }, [userId]);

  useEffect(() => {
    if (designs.length > 0) {
      setShowIdeasComponent(false);
    }
  }, [designs]);
  

  const handleMagicButtonClick = async () => {
    setLoading(true);
    setIsInitialLoading(true);
    setShowIdeasComponent(false);
    setLoadingMessage('Interpreting prompt...');
  
    try {
      const postData = {
        prompt: userInput,
        uid: userId,
      };
  
      console.log('Data to be sent:', postData);
  
      const interpretResponse = await axios.post(process.env.REACT_APP_INTERPRET_PROMPT_AND_GENERATE_DESIGN, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Wait for 2 seconds and then change the loading message
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoadingMessage('Generating designs...');

      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoadingMessage('Analyzing backgrounds...');
  
  
      if (interpretResponse.data && interpretResponse.data.data) {
        const { extracted_data, design_urls } = interpretResponse.data.data;
  
        setIsInitialLoading(false);
        setDesigns(design_urls); // Store the design URLs
        setIsFinalLoading(true); // Start final loading
        setLoadingMessage('Selecting optimal color...'); // Set initial message for final loading phase
  
        const generateProductData = {
          uid: userId,
          extracted_data: extracted_data,
          design_urls: design_urls,
          prompt: userInput,
        };
  
        // Start the second loading bar and reset it for 25 seconds
        const generateProductResponse = axios.post(process.env.REACT_APP_GENERATE_PRODUCT, generateProductData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        // Display the loading bar with different messages for 25 seconds
        await new Promise(resolve => setTimeout(resolve, 5000));
        setLoadingMessage('Selecting optimal size...');
  
        await new Promise(resolve => setTimeout(resolve, 5000));
        setLoadingMessage('Generating product images...');
  
        await new Promise(resolve => setTimeout(resolve, 15000));
  
        // Wait for the generate product response to complete
        const response = await generateProductResponse;
        console.log('Generate product response from server:', response.data);
  
        setLoading(false);
        setIsFinalLoading(false);
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.error('Error during request:', error);
      setLoading(false);
      setIsInitialLoading(false);
      setIsFinalLoading(false);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
    }
  };
  
  
  
  

  const handleImageClick = async (mockupURL) => {
    console.log('Navigating to /summary page with the following details:');

    if (!userId) {
      console.error('No user ID available');
      return;
    }

    if (!mockupURL) {
      console.error('Invalid mockup URL:', mockupURL);
      return;
    }

    try {
      const historyRef = collection(db, 'users', userId, 'history');
      const q = query(historyRef, where('mockup_url', '==', mockupURL));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const selectedImage = querySnapshot.docs[0].data();
        const { mockup_url, product, design_url, color, orientation, available_sizes, date_created, prompt, sku, title, description, tags, price } = selectedImage;
        console.log('Navigating with:', { mockup_url, product, design_url, color, orientation, available_sizes, date_created, prompt, sku, title, description, tags, price });
        navigate('/summary', {
          state: {
            mockupURL: mockup_url,
            product: product,
            designURL: design_url,
            color: color,
            orientation: orientation,
            availableSizes: available_sizes,
            dateCreated: date_created,
            prompt: prompt, // Pass the prompt to the summary page
            sku: sku,
            title: title,
            description: description,
            tags: tags,
            price: price
          }
        });
      } else {
        console.error('No matching image found in history data');
      }
    } catch (error) {
      console.error('Error fetching image data:', error);
    }
  };

  const handleIdeaButtonClick = () => {
    if (ideas.length > 0) {
      const randomIdea = ideas[Math.floor(Math.random() * ideas.length)];
      setUserInput(randomIdea || '');
    } else {
      console.warn('No ideas available');
    }
  };

  // Function to select 4 random ideas
  const getRandomIdeas = (ideas) => {
    const shuffled = ideas.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4);
  };

  const handleIdeaClick = (idea) => {
    setUserInput(idea);
    handleMagicButtonClick();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        height='100vh'
        sx={{ overflowY: 'auto', height: '100vh', paddingTop: '20px' }}
      >
        {showIdeasComponent && (
          <IdeasComponent ideas={getRandomIdeas(ideas)} onIdeaClick={handleIdeaClick} />
        )}
        <Box
          ref={gridRef}
          sx={{
            width: isMobile ? '100%' : '90%',
            padding: isMobile ? '0 20px' : '0 50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Center vertically
            alignItems: 'center', // Center horizontally
          }}
        >
          <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mt: isInitialLoading || (designs.length > 0 && isFinalLoading) ? 8 : 0 }}>
          {(isInitialLoading || (designs.length > 0 && isFinalLoading)) && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 'bold' }}>
                {loadingMessage}
              </Typography>
            </Grid>
          )}

          {isInitialLoading ? (
            Array.from(new Array(3)).map((_, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
                <Skeleton
                  variant="rectangular"
                  width='100%'
                  sx={{ borderRadius: '20px', minHeight: 400 }}
                />
                <LoadingBox duration={15000} />
              </Grid>
            ))
          ) : designs.length > 0 && isFinalLoading ? (
            <>
              {designs.map((design, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
                  <img src={design} alt={`Design ${index + 1}`} style={{ width: '100%', minHeight: 400, borderRadius: '20px' }} />
                </Grid>
              ))}
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                <LoadingBox duration={25000} />
              </Grid>
            </>
          ) : historyData.map((image, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} sx={{ position: 'relative', mt: 4 }}>
              <PlaceholderImage onClick={() => handleImageClick(image.mockup_url)}>
                <img src={image.mockup_url} alt={`Design ${index + 1}`} style={{ width: '100%', borderRadius: 'inherit' }} />
                <DesignOverlay src={image.design_url} className="design-overlay" />
                <InfoBox>
                  <Typography variant="h7" component="h7" style={{ fontWeight: 'bold' }}>$24.99</Typography>
                </InfoBox>
              </PlaceholderImage>
            </Grid>
          ))}
        </Grid>



        </Box>
  
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: isMobile ? '90%' : '60%',
            marginTop: '20px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            position: 'fixed',
            bottom: isMobile ? '10px' : '70px',
            left: '50%',
            transform: 'translateX(-50%)',
            p: '10px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          }}
        >
          <CustomTooltip title="Click for inspiration" placement='top' arrow>
            <IconButton
              onClick={handleIdeaButtonClick}
              sx={{
                height: '50px',
                width: '50px',
                backgroundColor: '#ffeb3b',
                color: '#000000',
                '&:hover': {
                  backgroundColor: '#fdd835',
                },
                borderRadius: '10px',
              }}
            >
              <LightbulbIcon />
            </IconButton>
          </CustomTooltip>
          <TextField
            placeholder="What are we making today?"
            variant="outlined"
            size="large"
            multiline
            minRows={1}
            maxRows={4}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter'){
                e.preventDefault();
                handleMagicButtonClick();
                setUserInput('');
              }
            }}
            sx={{
              flex: 1,
              mx: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
          <IconButton
            onClick={handleMagicButtonClick}
            disabled={loading}
            sx={{
              height: '50px',
              width: '50px',
              backgroundColor: loading ? '#cccccc' : '#FF001B',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: loading ? '#cccccc' : '#e2400f',
              },
              borderRadius: '10px',
            }}
          >
            <ArrowUpwardRoundedIcon />
          </IconButton>
        </Box>
      </Box>
    </ThemeProvider>
  );
  
  
  
};

export default Recommendations;
