import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  LinearProgress,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const theme = createTheme();

const PlaceholderImage = styled('div')(({ theme }) => ({
  minWidth: '300px',
  maxWidth: '600px',
  width: '100%',
  borderRadius: '20px',
  border: '1px solid #F5F5F5',
  position: 'relative',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    '& .design-overlay': {
      opacity: 1,
    },
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '100%',
    borderRadius: '10px',
  },
}));

const InfoBox = styled('div')({
  position: 'absolute',
  border: '1px solid #F5F5F5',
  bottom: 0,
  width: '100%',
  height: '12%',
  backgroundColor: '#FFFFFF',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
});

const GeneratingComponent = ({ loading, setLoading, designs }) => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (loading) {
      const maxDuration = 70; // maximum duration in seconds
      const interval = 1000; // interval in milliseconds
      const increment = 100 / maxDuration; // calculate increment based on the maximum duration

      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + increment;
          if (newProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          return newProgress;
        });
      }, interval);

      return () => clearInterval(timer);
    }
  }, [loading, setLoading]);

  useEffect(() => {
    if (!loading) {
      setProgress(0);
    }
  }, [loading]);

  const handleImageClick = (item) => {
    navigate('/product', {
      state: {
        mockup_url: item.mockupURL,
        design_url: item.designURL,
        title: item.title,
        price: item.price,
        tags: item.tags,
        description: item.description,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        paddingTop={isMobile ? '130px' : '100px'}
        overflowY="auto"
        sx={{ height: '100vh' }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          component="h4"
          sx={{
            fontWeight: 'bold',
            marginTop: '50px',
            marginBottom: '20px',
            textAlign: isMobile ? 'center' : 'left',
            width: isMobile ? '90%' : 'auto',
          }}
        >
          {loading ? 'Generating products...' : 'Generated'}
          {loading && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ width: isMobile ? '80%' : '100%', marginTop: '20px' }}
            />
          )}
        </Typography>
        {loading ? (
          <Box
            sx={{
              width: isMobile ? '100%' : '90%',
              padding: isMobile ? '0 20px' : '0 50px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              overflowY: 'auto',
              marginBottom: isMobile ? '70px' : '0px',
            }}
          >
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              {[0, 1, 2].map((index) => (
                <Grid item key={index} xs={12} sm={6} md={4} sx={{ position: 'relative' }}>
                  <PlaceholderImage>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="500px"
                      sx={{ borderRadius: '20px' }}
                    />
                    <InfoBox>
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="20%" />
                    </InfoBox>
                  </PlaceholderImage>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              width: isMobile ? '100%' : '90%',
              padding: isMobile ? '0 20px' : '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              overflowY: 'auto',
              marginBottom: isMobile ? '70px' : '0px',
            }}
          >
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              {designs.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} sx={{ position: 'relative' }}>
                  <PlaceholderImage onClick={() => handleImageClick(item)}>
                    <img
                      src={item.mockupURL}
                      alt={`Design ${index + 1}`}
                      style={{ width: '100%', borderRadius: 'inherit' }}
                    />
                    <InfoBox>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        ${item.price}
                      </Typography>
                    </InfoBox>
                  </PlaceholderImage>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default GeneratingComponent;
