import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

const generateRandomData = () => {
  const data = [];
  for (let i = 0; i < 30; i++) {
    data.push({
      date: new Date(Date.now() - i * 1000 * 60 * 60 * 24).toLocaleDateString(),
      revenue: Math.floor(Math.random() * 1000),
      cost: Math.floor(Math.random() * 500),
    });
  }
  return data.reverse();
};

const RealTimeAreaChart = () => {
  const [chartData, setChartData] = useState(generateRandomData());
  const [hoveredBalance, setHoveredBalance] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setChartData((prevData) => {
        const newEntry = {
          date: new Date().toLocaleDateString(),
          revenue: Math.floor(Math.random() * 1000),
          cost: Math.floor(Math.random() * 500),
        };
        return [...prevData.slice(1), newEntry];
      });
    }, 5000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!hoveredIndex) {
      const todayData = chartData[chartData.length - 1];
      const todayBalance = todayData.revenue - todayData.cost;
      setHoveredBalance(todayBalance);
      setHoveredDate("Today");
    }
  }, [chartData, hoveredIndex]);

  const data = {
    labels: chartData.map((entry) => entry.date),
    datasets: [
      {
        label: 'Balance',
        data: chartData.map((entry) => entry.revenue - entry.cost),
        borderColor: '#FF4C15',
        backgroundColor: 'rgba(255, 76, 21, 0.2)',
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (context) => {
            const balance = chartData[context.dataIndex].revenue - chartData[context.dataIndex].cost;
            setHoveredBalance(balance);
            setHoveredIndex(context.dataIndex);
            setHoveredDate(chartData[context.dataIndex].date);
            return `Balance: $${balance}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: '#f5f5f5',
        },
        ticks: {
          callback: function (value) {
            return `$${value}`;
          },
        },
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        const index = chartElement[0].index;
        setHoveredIndex(index);
        setHoveredBalance(chartData[index].revenue - chartData[index].cost);
        setHoveredDate(chartData[index].date);
      } else {
        setHoveredIndex(null);
        setHoveredBalance(null);
        setHoveredDate(null);
      }
    },
  };

  return (
    <Box>
      <h1>Sales</h1>
      <Box
        sx={{
          borderRadius: '20px',
          border: '1px solid #F5F5F5',
          padding: '16px',
          textAlign: 'left',
          mb: 2,
          width: '50%',
        }}
      >
        <Typography variant="h6" color="black">
          Balance {hoveredDate && <Typography variant="caption" color="black">({hoveredDate})</Typography>}
        </Typography>
        <Typography variant="h4" color="black">
          {hoveredBalance !== null ? `$${hoveredBalance}` : '$12345'}
        </Typography>
      </Box>
      <Box
        sx={{
          borderRadius: '20px',
          border: '1px solid #F5F5F5',
          padding: '16px',
        }}
      >
        <Line
          data={{
            ...data,
            datasets: data.datasets.map((dataset, index) => {
              if (index === 0) {
                return {
                  ...dataset,
                  pointBackgroundColor: hoveredIndex !== null ? '#FF4C15' : 'transparent',
                  pointBorderColor: hoveredIndex !== null ? '#FF4C15' : 'transparent',
                  pointRadius: hoveredIndex !== null ? Array(chartData.length).fill(0).map((_, i) => (i === hoveredIndex ? 5 : 0)) : 0,
                };
              }
              return dataset;
            }),
          }}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default RealTimeAreaChart;


