import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { refreshUserTokens } from '../../tokenHandling';
import axios from 'axios';

const stripePromise = loadStripe("pk_test_51Oeo43BWcHnkE9yGke54OPmfdoIZgVaEfWmVFObg4VvgH1vH6KqswUljMIGyKnNDP9ZOvgaWLeHqkQ6AHSIf51xe00HsRBhQg2");

const CheckoutForm = ({ productId, quantity }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);

    // Ensure productId and quantity are integers
    const parsedProductId = parseInt(productId, 10);
    const parsedQuantity = parseInt(quantity, 10);

    useEffect(() => {

        async function estimateCosts(oauthToken) {
            const url = 'http://127.0.0.1:8000/api/estimate-costs/';
            
            const headers = {
                'Authorization': `Bearer ${oauthToken}`,
                'Content-Type': 'application/json'
            };

            const recipient = {
                name: 'John Doe',
                address1: '19749 Dearborn St',
                city: 'Chatsworth',
                state_code: 'CA',
                country_code: 'US',
                zip: '91311'
            };

            const items = [
                {
                    variant_id: parsedProductId,
                    quantity: parsedQuantity,
                    files: [
                        {
                            url: 'https://picsum.photos/200'
                        }
                    ]
                }
            ];

            const body = JSON.stringify({
                recipient: recipient,
                items: items
            });

            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: body
            };

            try {
                const response = await fetch(url, requestOptions);
                const responseText = await response.text(); // Get the raw response text
                console.log('Raw response:', responseText); // Log the raw response text

                if (!response.ok) {
                    const errorData = JSON.parse(responseText);
                    throw new Error(errorData.error || 'Unknown error');
                }

                const data = JSON.parse(responseText);
                const total = data.result.costs.total;
                setTotalAmount(total);

                const paymentIntentResponse = await axios.post(process.env.REACT_APP_CREATE_PAYMENT_INTENT, {
                    amount: total * 100
                });

                if (paymentIntentResponse.status === 200) {
                    const paymentIntentData = paymentIntentResponse.data;
                    setClientSecret(paymentIntentData.clientSecret);
                } else {
                    setError('Failed to create payment intent');
                }
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            }
        }

        async function initializePayment() {
            try {
                const oauthToken = "EFnkPlCJDnQV6d3JWgM6MgEkYJPwKERmBgKjhlNU";
                await estimateCosts(oauthToken);
            } catch (error) {
                console.error('Initialization Error:', error);
                setError(error.message);
            }
        }

        initializePayment();
    }, [parsedProductId, parsedQuantity]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });

        if (error) {
            setError(error.message);
        } else if (paymentIntent.status === 'succeeded') {
            setSuccess(true);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button type="submit" disabled={!stripe || !clientSecret}>
                Pay ${(totalAmount / 100).toFixed(2)}
            </button>
            {error && <div>{error}</div>}
            {success && <div>Payment Successful!</div>}
        </form>
    );
};

const PaymentComponent = ({ productId, quantity }) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm productId={parseInt(productId, 10)} quantity={parseInt(quantity, 10)} />
    </Elements>
);

export default PaymentComponent;
