import React, { useState, useEffect } from 'react';
import Header from '../components/Headers/Header';
import LoginGenerate from '../components/Landing/LoginGenerate';
import Discover from './Discover'; // Ensure the path is correct
import { Box } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Ensure the path is correct

const Home = () => {
  const [user, setUser] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [designs, setDesigns] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100vh" width="100%" overflow="auto">
      <Header setDesigns={setDesigns} setLoadingState={setLoadingState} />
      {!user && <LoginGenerate setUser={setUser} />}
      <Discover />
    </Box>
  );
};

export default Home;
