import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import ClickAwayListener from '@mui/material/ClickAwayListener';

function Sidebar({ isOpen, handleToggleDrawer }) {
  const location = useLocation();
  const [feedback, setFeedback] = useState('');
  const [user, setUser] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSendFeedback = async () => {
    const feedbackEndpoint = process.env.REACT_APP_FEEDBACK;

    if (!user) {
      console.error('No user logged in!');
      return;
    }

    const userID = user.uid;
    const feedbackData = {
      feedback,
      userID
    };

    try {
      console.log('Sending feedback with userID:', feedback, userID);
      const response = await fetch(feedbackEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(feedbackData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Feedback sent successfully:', result);
      setFeedback('');
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  const drawerWidth = isOpen ? 220 : 0;
  const mobileDrawerWidth = isOpen ? '100%' : 0;
  const drawerHeight = isMobile ? '40%' : '100%'; // Adjust drawer height for mobile

  const handleClickAway = () => {
    if (isOpen) {
      handleToggleDrawer();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ display: 'flex' }}>
        {user && location.pathname === '/' && (
          <IconButton onClick={handleToggleDrawer} sx={{ position: 'fixed', top: 16, left: 16, zIndex: 1300 }}>
            {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        )}
        <Drawer
          sx={{
            width: isMobile ? mobileDrawerWidth : drawerWidth,
            height: drawerHeight,
            borderRadius: '20px',
            flexShrink: 0,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to drawer
            '& .MuiDrawer-paper': {
              borderRadius: '20px',
              width: isMobile ? mobileDrawerWidth : drawerWidth,
              height: drawerHeight,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          variant="persistent"
          anchor={isMobile ? 'top' : 'left'}
          open={isOpen}
        >
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '25px',
              marginTop: '10px', // Reduced margin below the logo
            }}
          >
            <Card sx={{ maxWidth: isMobile ? 60 : 80, borderRadius: '10px' }}>
              <CardMedia
                component="img"
                image="/logo.png"
                sx={{ width: isMobile ? 60 : 80, height: isMobile ? 60 : 80 }}
              />
            </Card>
          </Box>
          <Box sx={{ p: 2, flex: 1 }}>
            <List sx={{ padding: 0 }}>
              <ListItem disablePadding sx={{ marginBottom: '5px' }}>
                <ListItemButton
                  component={Link}
                  to="/"
                  sx={{
                    p: 1,
                    borderRadius: '10px',
                    backgroundColor: location.pathname === '/' ? '#FF001B' : 'transparent',
                    color: location.pathname === '/' ? '#FFFFFF' : 'inherit',
                    '&:hover': {
                      backgroundColor: location.pathname !== '/' ? '#e0e0e0' : '#FF001B',
                      color: location.pathname !== '/' ? '#000000' : '#FFFFFF',
                    },
                    '.MuiListItemIcon-root': {
                      color: location.pathname === '/' ? '#FFFFFF' : 'inherit',
                    },
                    '.MuiListItemText-primary': {
                      color: location.pathname === '/' ? '#FFFFFF' : 'inherit',
                    },
                  }}
                >
                  <ListItemIcon>
                    <HomeRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to="/settings"
                  sx={{
                    p: 1,
                    borderRadius: '10px',
                    backgroundColor: location.pathname === '/settings' ? '#FF001B' : 'transparent',
                    color: location.pathname === '/settings' ? '#FFFFFF' : 'inherit',
                    '&:hover': {
                      backgroundColor: location.pathname !== '/settings' ? '#e0e0e0' : '#FF001B',
                      color: location.pathname !== '/settings' ? '#000000' : '#FFFFFF',
                    },
                    '.MuiListItemIcon-root': {
                      color: location.pathname === '/settings' ? '#FFFFFF' : 'inherit',
                    },
                    '.MuiListItemText-primary': {
                      color: location.pathname === '/settings' ? '#FFFFFF' : 'inherit',
                    },
                  }}
                >
                  <ListItemIcon>
                    <ManageAccountsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          {!isMobile && (
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', borderColor: '#F5F5F5', borderWidth: '1px', borderRadius: '10px' }}>
              <TextField
                placeholder="How can we improve?"
                variant="outlined"
                multiline
                rows={4}
                value={feedback}
                onChange={handleFeedbackChange}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#F5F5F5',
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
              />
              <Button
                onClick={handleSendFeedback}
                sx={{
                  color: 'white',
                  marginTop: '5px',
                  width: '100%',
                  borderRadius: '10px',
                  backgroundColor: '#FF001B',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                    color: '#000000',
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          )}
        </Drawer>
      </Box>
    </ClickAwayListener>
  );
}

export default Sidebar;
