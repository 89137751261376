import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from 'axios';
import { Container, Box, Button, Typography, Chip, Skeleton, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Import the Firebase authentication and database instances
import TShirtSizeChart from '../components/Size_Charts/TShirtSizeChart';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ListingPage = () => {
    const [listingData, setListingData] = useState({
        description: '',
        name: '',
        price: '',
        productType: '',
        quantity: '',
        sku: '',
        tags: '',
        vendor: ''
    });
    const [mockupData, setMockupData] = useState({
        mockupURL: '',
        product: '',
        designURL: '',
        color: '',
        orientation: '',
        availableSizes: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'] // Add new sizes here
    });
    const [productData, setProductData] = useState({
        sku: '',
        designURL: '',
        mockupURL: '',
        availableSizes: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'],
        price: '',
        orientation: '',
        color: '',
        tags: '',
        prompt: '' // Ensure prompt is included
    });
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [size, setSize] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [productDocRef, setProductDocRef] = useState(null);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (location.state) {
            const { mockupURL, product, designURL, color, orientation, availableSizes, prompt } = location.state;
    
            setMockupData({
                mockupURL,
                product,
                designURL,
                color,
                orientation,
                availableSizes: availableSizes || ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']
            });
    
            console.log('Received props:', { mockupURL, product, designURL, color, orientation, prompt });
    
            sendPropsToServer({
                ...location.state,
                mockupURL
            });
        }
    }, [location.state]);

    const sendPropsToServer = async (props) => {
        try {
            console.log('Sending props to server:', props);

            const response = await axios.post(process.env.REACT_APP_NAMEOR_V2, props, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setListingData({
                description: response.data.description,
                name: response.data.name,
                price: response.data.price,
                productType: response.data.productType,
                quantity: response.data.quantity,
                sku: response.data.sku,
                tags: response.data.tags.join(', '),
                vendor: response.data.vendor
            });

            const productDataToUpload = {
                sku: response.data.sku,
                designURL: props.designURL,
                mockupURL: props.mockupURL,
                availableSizes: props.availableSizes,
                price: response.data.price,
                orientation: props.orientation,
                color: props.color,
                tags: response.data.tags.join(', '),
                title: response.data.name,
                description: response.data.description,
                prompt: props.prompt // Ensure prompt is included
            };

            console.log('Product data to upload:', productDataToUpload);

            setProductData(productDataToUpload);

            // Upload product data to Firebase
            const uploadResponse = await axios.post(process.env.REACT_APP_UPLOAD_PRODUCT, { productData: productDataToUpload }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Upload response:', uploadResponse.data);

            setProductDocRef(uploadResponse.data.productDocRef);
            setLoading(false);
        } catch (error) {
            console.error('Error sending props to server:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
            }
        }
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserData(user);
            } else {
                console.log("No user is signed in.");
            }
        });
    }, []);

    const fetchUserData = async (user) => {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            setUserEmail(user.email); // Assuming email is directly accessible from the auth user object
            setUserId(user.uid);
        } else {
            console.log("No user data found in Firestore");
        }
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const handleBackClick = () => {
        navigate('/home');
    };

    const handleCheckout = async () => {
        const stripe = await stripePromise;

        if (!userEmail) {
            console.error('Invalid email address:', userEmail);
            return;
        }

        const response = await axios.post(process.env.REACT_APP_CREATE_PAYMENT_INTENT, {
            amount: productData.price * 100, // Stripe expects the amount in cents
            mockup_url: productData.mockupURL, // Send mockup URL to the backend
            title: listingData.name, // Send the product title to the backend
            customer_email: userEmail, // Send customer email to the backend
            success_url: window.location.origin + '/success',
            cancel_url: window.location.origin + '/cancel',
            product_doc_ref: productDocRef, // Pass product document reference
            user_id: userId, // Pass user ID
            size: size // Pass the selected size
        });

        const session = response.data;

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return (
        <Container maxWidth={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: { xs: 2, md: 0 } }}>
            <IconButton onClick={handleBackClick} sx={{ position: 'fixed', left: { xs: '10px', md: '190px' }, top: { xs: '10px', md: '80px' } }}>
                <ArrowBack />
            </IconButton>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" sx={{ width: { xs: '100%', md: '90%' }, backgroundColor: '#fff', marginTop: { xs: '80%', md: '50px' }, borderRadius: '20px', padding: { xs: '20px', md: '100px' } }}>
            <Box 
                flex="1" 
                pr={{ md: 6 }} 
                mb={{ xs: 2, md: 0 }} 
                sx={{ 
                    maxHeight: { xs: '300px', md: '600px' }, 
                    overflowY: 'auto', 
                    scrollBehavior: 'smooth' 
                }}
            >
                {loading ? (
                    <>
                    <Skeleton variant="rectangular" width="100%" minHeight={{ xs: '300px', md: '600px' }} sx={{ borderRadius: '20px' }} />
                    <Skeleton variant="rectangular" width="100%" minHeight={{ xs: '300px', md: '600px' }} sx={{ borderRadius: '20px' }} />
                    </>
                ) : (
                    <>
                        <Box component="img" src={mockupData.mockupURL} alt="Mockup Image" sx={{ width: '100%', height: 'auto', maxHeight: { xs: '300px', md: '600px' }, borderRadius: '20px', objectFit: 'cover', border: '2px solid #f5f5f5', mt: 2 }} />
                        <Box component="img" src={mockupData.designURL} alt="Design Image" sx={{ width: '100%', height: 'auto', maxHeight: { xs: '300px', md: '600px' }, borderRadius: '20px', objectFit: 'cover', border: '2px solid #f5f5f5', mt: 2 }} />
                    </>
                )}
            </Box>
                <Box flex="1" pl={{ md: 6 }}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ borderRadius: '10px' }} />
                    ) : (
                        <Typography variant="h5" align="left" sx={{ mb: '20px', fontWeight: 'bold' }}>{listingData.name}</Typography>
                    )}
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <Typography variant="h5" align="left" sx={{ fontWeight: 'bold' }}>${listingData.price}</Typography>
                    )}
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={112} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <Typography variant="body1" sx={{ mt: '20px' }}>{listingData.description}</Typography>
                    )}
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <>
                            <Typography variant="h6" sx={{ mt: '20px', fontWeight: 'bold' }}>Tags</Typography>
                            {listingData.tags.split(', ').map((tag, index) => (
                                <Chip key={index} label={tag} sx={{ backgroundColor: '#F5F5F5', borderRadius: '20px', mr: 1, mt: 1 }} />
                            ))}
                        </>
                    )}
                    <FormControl fullWidth sx={{ mt: '20px' }}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={56} sx={{ borderRadius: '10px' }} />
                        ) : (
                            <>
                                <InputLabel id="size-select-label">Choose a size</InputLabel>
                                <Select labelId="size-select-label" id="size-select" value={size} label="Choose a size" onChange={handleSizeChange}>
                                    {mockupData.availableSizes.map((sizeOption, index) => (
                                        <MenuItem key={index} value={sizeOption}>{sizeOption}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    </FormControl>

                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height='auto' sx={{ borderRadius: '10px', mt: '20px' }} />
                    ) : (
                        <TShirtSizeChart/> // Pass loading prop here
                    )}

                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            fullWidth 
                            sx={{ marginTop: '20px', borderRadius: '20px', backgroundColor: '#FF001B', minHeight: '60px' }} 
                            onClick={handleCheckout}
                            disabled={!size}
                        >
                            Buy Now
                        </Button>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default ListingPage;
