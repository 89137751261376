import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, TextField, IconButton, Snackbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PropTypes from 'prop-types';
import { auth, db, analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

const GoogleButton = styled(Button)(({ theme }) => ({
  color: '#ff0066',
  borderColor: '#ff0066',
  fontWeight: 'bold',
  borderRadius: '10px',
  height: '50px',
  width: '150px',
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    width: '100px',
  },
}));

const GenerateHeader = ({ setLoadingState, inputValue, setInputValue, userId, setUserId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [ideas, setIdeas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    logEvent(analytics, "Generate-page Visited");

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserId(user.uid);
        fetchUserData(user);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, [setUserId]);

  const fetchUserData = async (user) => {
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      if (userData.ideas && userData.ideas.recommendations && Array.isArray(userData.ideas.recommendations)) {
        setIdeas(userData.ideas.recommendations.map(r => r.prompt));
      }
    } else {
      console.log("No user data found in Firestore");
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleMagicButtonClick = () => {
    if (!inputValue.trim() || !userId) {
      setErrorMessage('Please enter a prompt and make sure you are logged in.');
      setSnackbarOpen(true);
      return;
    }

    setLoadingState(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleIdeaButtonClick = () => {
    if (ideas.length > 0) {
      const randomIdea = ideas[Math.floor(Math.random() * ideas.length)];
      setInputValue(randomIdea || '');
    } else {
      console.warn('No ideas available');
    }
  };

  return (
    <>
      <AppBar position="fixed" color="transparent" elevation={0} sx={{ zIndex: 1, padding: isMobile ? '20px' : '30px', paddingTop: isMobile ? '20px' : '40px', backgroundColor: '#FFFFFF', borderRadius: '0 0 20px 20px' }}>
        <Toolbar sx={{ justifyContent: 'space-between', position: 'relative', flexDirection: isMobile ? 'column' : 'row' }}>
          {isMobile && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2 }}>
              <img
                src="/logo_white.png"
                alt="Logo"
                style={{ width: 70, height: 'auto' }}
              />
            </Box>
          )}
          {isLoggedIn && (
            <Box
              sx={{
                position: isMobile ? 'relative' : 'absolute',
                top: isMobile ? 'auto' : '50%',
                left: isMobile ? 'auto' : '50%',
                transform: isMobile ? 'none' : 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: isMobile ? '100%' : '70%',
                borderRadius: '20px',
                border: '2px solid #F5F5F5',
                p: '5px',
                paddingRight: '10px',
                marginTop: isMobile ? '10px' : '0px',
              }}
            >
              <IconButton
                onClick={handleIdeaButtonClick}
                sx={{
                  height: '50px',
                  width: '50px',
                  backgroundColor: '#FFFFFF',
                  marginRight: '10px',
                  color: '#FF001B',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                  borderRadius: '10px',
                  marginLeft: '10px',
                  border: '2px solid #FF001B',
                }}
              >
                <LightbulbIcon />
              </IconButton>
              <TextField
                placeholder="What are we making today?"
                variant="outlined"
                size="large"
                multiline
                minRows={1}
                maxRows={4}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleMagicButtonClick();
                  }
                }}
                sx={{
                  flex: 1,
                  mx: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
              />
              <IconButton
                onClick={handleMagicButtonClick}
                disabled={isLoading}
                sx={{
                  height: '50px',
                  width: '50px',
                  backgroundColor: isLoading ? '#CCCCCC' : '#FF001B',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: isLoading ? '#CCCCCC' : '#e2400f',
                  },
                  borderRadius: '10px',
                }}
              >
                <ArrowUpwardRoundedIcon />
              </IconButton>
            </Box>
          )}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
              <img
                src="/logo_white.png"
                alt="Logo"
                style={{ width: 150, height: 'auto' }}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ paddingTop: isMobile ? '120px' : '80px' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={errorMessage}
          key={'topcenter'}
        />
      </Box>
    </>
  );
};

GenerateHeader.propTypes = {
  setLoadingState: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  setUserId: PropTypes.func.isRequired,
};

export default GenerateHeader;
