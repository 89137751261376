// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQB_v1TCjjzWr7HDzoqmXwB6bhA8bYJ1g",
  authDomain: "droyd-v3.firebaseapp.com",
  databaseURL: "https://droyd-v3-default-rtdb.firebaseio.com",
  projectId: "droyd-v3",
  storageBucket: "droyd-v3.appspot.com",
  messagingSenderId: "1083146413794",
  appId: "1:1083146413794:web:3f44eac6fc025e4a4460bb",
  measurementId: "G-K3SKYE14SP"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
