import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, Grid, IconButton, Tab, Tabs, styled, Snackbar } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { fetchImageURLPrompt, deleteImage, fetchFavorites } from '../imageStorageHandling';
import { auth, db } from '../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import CloseIcon from '@mui/icons-material/Close';

// Styled components for tabs, using styles provided from Settings component
const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#FFFFFF',
  },
});

const CustomTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  marginBottom: '20px',
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontFamily: theme.typography.fontFamily,
  '&.Mui-selected': {
    color: '#000',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(255, 76, 21, 0.32)',
  },
}));

function SavedPage() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState('all'); // Default tab set to 'all'
  const [favorites, setFavorites] = useState([]);  // Add this line to store favorites
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      setError('User not authenticated');
      setLoading(false);
      return;
    }

    setLoading(true);

    const fetchAllImages = async () => {
      try {
        const imagesWithPrompts = await fetchImageURLPrompt(user);
        console.log("FETCHING ALL IMAGES");
        console.log(imagesWithPrompts);
        setImages(imagesWithPrompts || []);
        setError(null);
      } catch (error) {
        console.error('Error fetching images:', error);
        setError('Failed to fetch images. Please try again later.');
      }
    };

    const fetchFavoritesOnly = async () => {
      try {
        const favs = await fetchFavorites(user);
        setFavorites(favs || []);
        setError(null);
      } catch (error) {
        console.error('Error fetching favorites:', error);
        setError('Failed to fetch favorites. Please try again later.');
      }
    };

    if (tabValue === 'all') {
      fetchAllImages().then(() => setLoading(false));
    } else if (tabValue === 'favorites') {
      fetchFavoritesOnly().then(() => setLoading(false));
    }
  }, [tabValue]); // React only when tabValue changes to minimize unnecessary calls

  const handleImageDownload = (imageUrl) => {
    const anchor = document.createElement('a');
    anchor.href = imageUrl;
    anchor.download = `downloaded_image_${new Date().getTime()}.jpg`;
    anchor.target = '_blank';
    anchor.click();
  };

  const handleDelete = async (index, imageUrl) => {
    try {
      await deleteImage(imageUrl);
      setImages((current) => current.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete the image. Please try again later.');
    }
  };

  const changeToMockups = (imageUrl, prompt) => {
    
    navigate('/mockups', {
      state: {
        image_url_to_mockups: imageUrl,
        prompt_to_mockups: prompt,
        dalle_image: imageUrl,
      },
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': { // Targeting the internal Paper component of Snackbar
            borderRadius: '20px',
            backgroundColor: '#F5F5F5',
            color: 'black' // Optional: setting text color
          }
        }}
        action={ // Adding the action
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <h1>Saved</h1>
      <Typography variant="subtitle1" sx={{ marginBottom: '20px' }}>Your saved designs will show up here.</Typography>
      <CustomTabs value={tabValue} onChange={handleTabChange} textColor="inherit" aria-label="saved designs tabs">
        <CustomTab value="all" label="All" />
        <CustomTab value="favorites" label="Favorites" />
      </CustomTabs>
      {tabValue === 'all' && <ImageGrid images={images} favorites={favorites} setFavorites={setFavorites} handleDelete={handleDelete} handleImageDownload={handleImageDownload} changeToMockups={changeToMockups} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} />}
      {tabValue === 'favorites' && <ImageGrid images={favorites} favorites={favorites} setFavorites={setFavorites} handleDelete={handleDelete} handleImageDownload={handleImageDownload} changeToMockups={changeToMockups} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} />}
    </Box>
  );
}

// Inside your ImageGrid component
const ImageGrid = ({ images, favorites, setFavorites, handleDelete, handleImageDownload, changeToMockups, setSnackbarOpen, setSnackbarMessage }) => {

  const toggleFavorite = async (imageUrl, prompt) => {
    console.log("WE ARE IN TOGGLE FAVORITE HERE IS IMAGEURL AND PROMPT:");
    console.log(imageUrl);
    console.log(prompt);
    const user = auth.currentUser;
    if (!user) {
      console.log('No user logged in');
      return;
    }
    const userId = user.uid;
    const userDocRef = doc(db, 'users', userId);
  
    // Convert favorites to a set of dictionaries with imageUrl and prompt
    let updatedFavorites = new Set(favorites.map(fav => JSON.stringify({ imageUrl: fav.imageUrl, prompt: fav.prompt })));
    let favoriteStatus = false;
    let message = ''; // Message to display in the snackbar
    const currentFavorite = JSON.stringify({ imageUrl, prompt });
  
    if (updatedFavorites.has(currentFavorite)) {
      updatedFavorites.delete(currentFavorite);
      favoriteStatus = false;
      message = 'Image Removed from Favorites'; // Set message for removing from favorites
    } else {
      updatedFavorites.add(currentFavorite);
      favoriteStatus = true;
      message = 'Image Added to Favorites'; // Set message for adding to favorites
      console.log('Image Favorited!');
    }
    console.log(Array.from(updatedFavorites).map(item => JSON.parse(item)));
    setSnackbarMessage(message); // Set the message for the snackbar
    setSnackbarOpen(true); // Open the snackbar to show the message
  
    try {
      const response = await fetch(process.env.REACT_APP_FAVORITE_DESIGN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: userId,
          image_url: imageUrl,
          prompt: prompt,  // Pass the prompt
          favorite_status: favoriteStatus
        })
      });
      const data = await response.json();
      if (response.ok) {
        console.log('API Response:', data.message);
        setFavorites(Array.from(updatedFavorites).map(item => JSON.parse(item))); // Update the favorites state to trigger re-render
      } else {
        throw new Error(data.message || 'Something went wrong with the API');
      }
    } catch (error) {
      console.error('API Error:', error.message);
    }
  };
  return (
    <Grid container spacing={2}>
      {images.length ? images.map((image, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box sx={{ position: 'relative', display: 'flex', borderRadius: '20px', justifyContent: 'center', alignItems: 'center', border: '1px solid #F5F5F5', transition: '0.3s', '&:hover': { boxShadow: '0px 3px 6px rgba(255, 76, 21, 1)'}, cursor: 'pointer' }}
            onClick={() => changeToMockups(image.imageUrl, image.prompt)}>
            <img src={image.imageUrl} alt={`Design ${index + 1}`} style={{ width: '100%', height: 'auto', borderRadius: '20px' }} />
            <IconButton onClick={(event) => { event.stopPropagation(); handleDelete(index, image.imageUrl); }} sx={{ position: 'absolute', top: '10px', right: '10px', color: 'black', backgroundColor: '#F5F5F5', '&:hover': { backgroundColor: '#E0E0E0' } }}>
              <DeleteRoundedIcon />
            </IconButton>
            <IconButton onClick={(event) => { event.stopPropagation(); handleImageDownload(image.imageUrl); }} sx={{ position: 'absolute', top: '10px', right: '60px', color: 'black', backgroundColor: '#F5F5F5', '&:hover': { backgroundColor: '#E0E0E0' } }}>
              <GetAppRoundedIcon />
            </IconButton>
            <IconButton onClick={(event) => { event.stopPropagation(); toggleFavorite(image.imageUrl, image.prompt); }} sx={{ position: 'absolute', top: '10px', left: '10px', color: 'black', backgroundColor: '#F5F5F5', '&:hover': { backgroundColor: '#E0E0E0' } }}>
              {favorites.some(fav => fav.imageUrl === image.imageUrl) ? <FavoriteRoundedIcon /> : <FavoriteBorderRoundedIcon />}
            </IconButton>
          </Box>
        </Grid>
      )) : <Typography variant="h6" sx={{ margin: 'auto' }}>No designs found.</Typography>}
    </Grid>
  );
};

export default SavedPage;
