import React, { useState } from 'react';
import axios from 'axios';

const Test = () => {
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');

  const fetchMockups = async () => {
    try {
      const res = await axios.post(process.env.REACT_APP_GET_MOCKUPS);
      setResponse(JSON.stringify(res.data, null, 2)); // Format the response for better readability
    } catch (err) {
      setError('An error occurred while fetching the mockups.');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <button onClick={fetchMockups}>Fetch Mockups</button>
      {response && (
        <div style={{ marginTop: '20px' }}>
          <h2>Response:</h2>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{response}</pre>
        </div>
      )}
      {error && (
        <div style={{ marginTop: '20px' }}>
          <h2>Error:</h2>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{error}</pre>
        </div>
      )}
    </div>
  );
};

export default Test;
