import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, TextField, Card, CardMedia, Snackbar, IconButton } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase'; // Import the Firebase authentication instance
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Importing MUI ArrowBack icon

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Check your email to reset your password.');
            setSnackbarOpen(true);
            setTimeout(() => navigate('/'), 3000); // Redirect to login after showing message
        } catch (error) {
            console.error('Failed to send reset email:', error);
            setMessage('Failed to send reset email. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: '#ffffff', // Set background color to white
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', // Center horizontally
                height: '100vh',
                width: '100%',
                padding: '0 20px',
                position: 'relative' // Position relative for absolute positioning of IconButton
            }}
        >
            <IconButton
                onClick={() => navigate('/')}
                sx={{
                    position: 'absolute',
                    top: '50px',
                    left: '20px'
                }}
            >
                <ArrowBackIcon />
            </IconButton>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={message}
                key={'topcenter'}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                    width: '100%',
                    maxWidth: '500px',
                    borderRadius: '20px',
                    padding: { xs: '20px', sm: '50px' },
                    backgroundColor: '#ffffff',
                    boxShadow: { xs: 'none', sm: '0px 4px 12px rgba(0, 0, 0, 0.1)' } // No shadow on mobile, shadow on desktop
                }}
            >
                <Card sx={{ borderRadius: '10px', width: 80, height: 80 }}>
                    <CardMedia
                        component="img"
                        image="/logo.png"
                        sx={{ width: 80, height: 80 }}
                    />
                </Card>
                <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Reset Password
                </Typography>
                <TextField
                    label="Email"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ width: '100%' }}
                />
                <Button
                    variant="contained"
                    onClick={handleResetPassword}
                    sx={{ backgroundColor: '#ff4c15ff', borderRadius: '10px', width: '100%', minHeight: '50px' }}
                >
                    Send Reset Email
                </Button>
            </Box>
        </Box>
    );
}

export default ResetPassword;
