import React, { useState, useEffect } from 'react';
import { Box, Grid, Skeleton, Tooltip, tooltipClasses, useMediaQuery, Typography, createTheme, ThemeProvider, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this is the correct path to your firebase setup
import { useNavigate } from 'react-router-dom';

const theme = createTheme();

const PlaceholderImage = styled('div')(({ theme }) => ({
  minWidth: '300px',
  maxWidth: '600px',
  width: '100%',
  borderRadius: '20px',
  border: '1px solid #F5F5F5',
  position: 'relative',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
    '& .design-overlay': {
      opacity: 1,
    },
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '100%',
    borderRadius: '10px',
  },
}));

const InfoBox = styled('div')({
  position: 'absolute',
  border: '1px solid #F5F5F5',
  bottom: 0,
  width: '100%',
  height: '12%',
  backgroundColor: '#FFFFFF',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
});

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '1rem',
    padding: '10px',
    backgroundColor: '#000000',
    fontWeight: 'bold'
  },
}));

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root': {
    padding: '5px',
    fontWeight: 'bold',
    marginBottom: '30px',
    borderRadius: '20px',
    '&.Mui-selected': {
      backgroundColor: '#F5F5F5',
      color: '#000000', // Change the font color of the selected tab
    },
  },
  '@media (max-width: 600px)': { // Adjust the max-width value as needed for your mobile breakpoint
    '& .MuiTab-root': {
      marginTop: '20px', // Increase marginTop for mobile devices
    },
  },
});


const Discover = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Men');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const productsRef = collection(db, 'products');
      const q = query(productsRef, orderBy('date_created', 'desc'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsData);
        setLoading(false);
      }, (error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    };

    fetchProducts();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleImageClick = (product) => {
    console.log('Clicked product data:', product);
    navigate('/product', { state: product });
  };

  const filteredProducts = products.filter(product => {
    const orientations = selectedTab === 'Men' 
      ? ['men_1', 'men_2', 'men_3', 'men_4'] 
      : ['women_1', 'women_2', 'women_3', 'women_4'];
    return orientations.some(orientation => product.orientation === orientation);
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        paddingTop="60px"
      >
        <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="product tabs">
          <Tab label="Men" value="Men" />
          <Tab label="Women" value="Women" />
        </StyledTabs>
        <Box
          sx={{
            width: isMobile ? '100%' : '90%',
            padding: isMobile ? '0 20px' : '0 50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {loading ? (
              Array.from(new Array(3)).map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Skeleton variant="rectangular" minWidth='300px' maxWidth='600px' width='100%' borderRadius='20px' height='500px' top='100px' sx={{ borderRadius: '20px' }} />
                </Grid>
              ))
            ) : (
              filteredProducts.map((product, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} sx={{ position: 'relative' }}>
                  <PlaceholderImage onClick={() => handleImageClick(product)}>
                    <img src={product.mockup_url} alt={`Product ${index + 1}`} style={{ width: '100%', borderRadius: 'inherit' }} />
                    <InfoBox>
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>{product.title}</Typography>
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>${product.price}</Typography>
                    </InfoBox>
                  </PlaceholderImage>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Discover;
