import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Chip, Skeleton, MenuItem, Select, FormControl, InputLabel, IconButton, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import TShirtSizeChart from '../components/Size_Charts/TShirtSizeChart';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ProductPage = () => {
    const [productData, setProductData] = useState({
        mockupURL: '',
        designURL: '',
        name: '',
        price: '',
        description: '',
        tags: [],
        availableSizes: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']
    });
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [size, setSize] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [productDocRef, setProductDocRef] = useState(null);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (location.state) {
            const { mockup_url, design_url, title, price, description, tags = [], availableSizes } = location.state;

            setProductData({
                mockupURL: mockup_url,
                designURL: design_url,
                name: title,
                price,
                description,
                tags: typeof tags === 'string' ? tags.split(',').map(tag => tag.trim()) : tags,
                availableSizes: availableSizes || ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']
            });

            setLoading(false);
        }
    }, [location.state]);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserData(user);
            } else {
                console.log("No user is signed in.");
            }
        });
    }, []);

    const fetchUserData = async (user) => {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            setUserEmail(user.email);
            setUserId(user.uid);
        } else {
            console.log("No user data found in Firestore");
        }
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const handleBackClick = () => {
        navigate('/');
    };

    const handleCheckout = async () => {
        const stripe = await stripePromise;

        if (!userEmail) {
            console.error('Invalid email address:', userEmail);
            return;
        }

        const response = await axios.post(process.env.REACT_APP_CREATE_PAYMENT_INTENT, {
            amount: productData.price * 100, // Stripe expects the amount in cents
            mockup_url: productData.mockupURL, // Send mockup URL to the backend
            title: productData.name, // Send the product title to the backend
            customer_email: userEmail, // Send customer email to the backend
            success_url: window.location.origin + '/success',
            cancel_url: window.location.origin + '/cancel',
            product_doc_ref: productDocRef, // Pass product document reference
            user_id: userId, // Pass user ID
            size: size // Pass the selected size
        });

        const session = response.data;

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return (
        <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: { xs: 2, md: 0 }, overflowX: 'hidden' }}>
            <Box 
                component="img" 
                src="/logo_white.png" 
                alt="Logo" 
                sx={{ 
                    position: 'fixed', 
                    top: { xs: '10px', md: '20px' }, 
                    width: { xs: '150px', md: '200px' }, 
                    zIndex: 1000 
                }} 
            />
            <IconButton onClick={handleBackClick} sx={{ position: 'fixed', left: { xs: '20px', md: '50px' }, top: { xs: '20px', md: '50px' } }}>
                <ArrowBack />
            </IconButton>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" sx={{ width: { xs: '80%', md: '90%' }, backgroundColor: '#fff', marginTop: { xs: '50%', md: '0px' }, borderRadius: '20px', padding: { xs: '20px', md: '100px' }, boxSizing: 'border-box' }}>
                <Box 
                    flex="1" 
                    pr={{ md: 6 }} 
                    mb={{ xs: 2, md: 0 }} 
                    sx={{ 
                        maxHeight: { xs: '300px', md: '800px' }, 
                        overflowY: 'auto', 
                        scrollBehavior: 'smooth',
                        width: '100%' 
                    }}
                >
                    {loading ? (
                        <>
                        <Skeleton variant="rectangular" width="100%" minHeight={{ xs: '300px', md: '600px' }} sx={{ borderRadius: '20px' }} />
                        <Skeleton variant="rectangular" width="100%" minHeight={{ xs: '300px', md: '600px' }} sx={{ borderRadius: '20px' }} />
                        </>
                    ) : (
                        <>
                            <Box 
                                component="img" 
                                src={productData.mockupURL} 
                                alt="Mockup Image" 
                                sx={{ 
                                    width: '100%', 
                                    height: 'auto', 
                                    maxHeight: { xs: '300px', md: '600px' }, 
                                    borderRadius: '20px', 
                                    objectFit: 'cover', 
                                    border: '2px solid #f5f5f5', 
                                    mt: 2 
                                }} 
                            />
                            <Box 
                                component="img" 
                                src={productData.designURL} 
                                alt="Design Image" 
                                sx={{ 
                                    width: '100%', 
                                    height: 'auto', 
                                    maxHeight: { xs: '300px', md: '600px' }, 
                                    borderRadius: '20px', 
                                    objectFit: 'cover', 
                                    border: '2px solid #f5f5f5', 
                                    mt: 2 
                                }} 
                            />
                        </>
                    )}
                </Box>
                <Box flex="1" pl={{ md: 6 }} sx={{ width: '100%' }}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ borderRadius: '10px' }} />
                    ) : (
                        <Typography variant="h5" align="left" sx={{ mb: '20px', fontWeight: 'bold' }}>{productData.name}</Typography>
                    )}
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <Typography variant="h5" align="left" sx={{ fontWeight: 'bold' }}>${productData.price}</Typography>
                    )}
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={112} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <Typography variant="body1" sx={{ mt: '20px' }}>{productData.description}</Typography>
                    )}
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <>
                            <Typography variant="h6" sx={{ mt: '20px', fontWeight: 'bold' }}>Tags</Typography>
                            {productData.tags.map((tag, index) => (
                                <Chip key={index} label={tag} sx={{ backgroundColor: '#F5F5F5', borderRadius: '20px', mr: 1, mt: 1 }} />
                            ))}
                        </>
                    )}
                    <FormControl fullWidth sx={{ mt: '20px' }}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={56} sx={{ borderRadius: '10px' }} />
                        ) : (
                            <>
                                <InputLabel id="size-select-label">Choose a size</InputLabel>
                                <Select labelId="size-select-label" id="size-select" value={size} label="Choose a size" onChange={handleSizeChange}>
                                    {productData.availableSizes.map((sizeOption, index) => (
                                        <MenuItem key={index} value={sizeOption}>{sizeOption}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    </FormControl>

                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height='auto' sx={{ borderRadius: '10px', mt: '20px' }} />
                    ) : (
                        <TShirtSizeChart/>
                    )}

                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={80} sx={{ mt: 4, borderRadius: '10px' }} />
                    ) : (
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            fullWidth 
                            sx={{ marginTop: '20px', borderRadius: '20px', backgroundColor: '#FF001B', minHeight: '60px' }} 
                            onClick={handleCheckout}
                            disabled={!size}
                        >
                            Buy Now
                        </Button>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default ProductPage;
