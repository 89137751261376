import React, { useState } from 'react';
import { Box, Typography, TextField, CircularProgress, IconButton } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

const BrandDataInput = ({ onNext }) => {
  const [firstName, setFirstName] = useState('');
  const [themeType, setThemeType] = useState('');
  const [colorChoice, setColorChoice] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleNext = () => {
    const brandData = { firstName, themeType, colorChoice };
    onNext(brandData);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        position: 'relative',
        padding: '0 20px',
      }}
    >
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '20px' }}>
            Generating prompt ideas...
          </Typography>
        </Box>
      ) : (
        <>
          <IconButton
            aria-label="back"
            onClick={() => navigate('/')}
            sx={{ position: 'absolute', top: '50px', left: '50px' }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              width: '100%',
              maxWidth: '600px',
              borderRadius: '20px',
              padding: { xs: '20px', sm: '50px' },
              backgroundColor: '#ffffff',
              boxShadow: { xs: 'none', sm: '0px 4px 12px rgba(0, 0, 0, 0.1)' },
              position: 'relative'
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              What is your first name?
            </Typography>
            <TextField
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              placeholder='Marco'
              InputLabelProps={{ style: { color: '#FF001B' } }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#F5F5F5',
                  marginBottom: '20px',
                  '& fieldset': { borderColor: 'transparent' },
                  '&:hover fieldset': { borderColor: 'transparent' },
                  '&.Mui-focused fieldset': { borderColor: 'transparent' },
                },
              }}
            />

            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              What topics are you interested in?
            </Typography>
            <TextField
              value={themeType}
              onChange={(e) => setThemeType(e.target.value)}
              placeholder='e.g., Cars, Christmas, Animals, Robots, Cowboys...'
              fullWidth
              InputLabelProps={{ style: { color: '#FF001B' } }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#F5F5F5',
                  marginBottom: '20px',
                  '& fieldset': { borderColor: 'transparent' },
                  '&:hover fieldset': { borderColor: 'transparent' },
                  '&.Mui-focused fieldset': { borderColor: 'transparent' },
                },
              }}
            />

            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              What colors do you like?
            </Typography>
            <TextField
              value={colorChoice}
              onChange={(e) => setColorChoice(e.target.value)}
              placeholder="e.g., Red, Blue, Black, White"
              fullWidth
              InputLabelProps={{ style: { color: '#FF001B' } }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#F5F5F5',
                  marginBottom: '20px',
                  '& fieldset': { borderColor: 'transparent' },
                  '&:hover fieldset': { borderColor: 'transparent' },
                  '&.Mui-focused fieldset': { borderColor: 'transparent' },
                },
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <IconButton
                aria-label="next"
                onClick={handleNext}
                sx={{
                  backgroundColor: '#FF001B',
                  color: '#FFFFFF',
                  '&:hover': { backgroundColor: '#e2400f' },
                  borderRadius: '10px',
                  width: '50px',
                  height: '50px',
                }}
              >
                <ArrowForwardRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default BrandDataInput;
