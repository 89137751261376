import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const session_id = query.get('session_id');
    const productDataString = query.get('productData');

    const fulfillOrder = async (sessionId) => {
      try {
        const response = await fetch(process.env.REACT_APP_FULFILL_ORDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ session_id: sessionId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fulfill order');
        }

        console.log('Order fulfilled successfully');
      } catch (error) {
        console.error('Error fulfilling order:', error);
      }
    };

    if (session_id) {
      fulfillOrder(session_id);
    }

    if (productDataString) {
      setProductData(JSON.parse(decodeURIComponent(productDataString)));
    }

    const timer = setTimeout(() => {
      navigate('/home');
    }, 5000); // Redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate, location.search]);

  return (
    <div style={{ textAlign: 'center' }}>
      <img src="/logo.png" alt="Droyd Logo" style={{ width: '200px', height: '200px', borderRadius: '20px', marginBottom: '20px' }} />
      <Typography variant="h5" style={{ marginTop: '20px', fontWeight: 'bold' }}>
        Success! You'll receive a confirmation email shortly.
      </Typography>
    </div>
  );
};

export default Success;
