import React, { useState } from 'react';
import { Typography, Grid, Box, CircularProgress, IconButton, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { auth, db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

const placeholderImageUrl = 'https://via.placeholder.com/200';

const styleOptions = [
  { id: 'Glitch_Art', label: 'Glitch Art', image: placeholderImageUrl },
  { id: 'Retrowave', label: 'Retrowave', image: placeholderImageUrl },
  { id: 'Retro', label: 'Retro', image: placeholderImageUrl },
  { id: 'Low_Poly_Art', label: 'Low Poly Art', image: placeholderImageUrl },
  { id: 'Cyberpunk', label: 'Cyberpunk', image: placeholderImageUrl },
  { id: 'Psychedelic_Art', label: 'Psychedelic Art', image: placeholderImageUrl },
  { id: 'Vintage', label: 'Vintage', image: placeholderImageUrl },
  { id: 'Line_Art', label: 'Line Art', image: placeholderImageUrl },
  { id: 'Minimalist_Typography', label: 'Minimalist Typography', image: placeholderImageUrl },
  { id: 'Synthwave', label: 'Synthwave', image: placeholderImageUrl },
  { id: 'Vaporwave', label: 'Vaporwave', image: placeholderImageUrl },
  { id: 'Pixel_Art', label: 'Pixel Art', image: placeholderImageUrl },
];

function Preferences({ brandData, onNext }) {
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleStyleSelect = (style) => {
    setSelectedStyles((prevStyles) =>
      prevStyles.includes(style)
        ? prevStyles.filter((s) => s !== style)
        : [...prevStyles, style]
    );
  };

  const saveBrandDataToFirestore = async () => {
    if (!auth.currentUser) {
      console.error('No authenticated user found');
      return;
    }

    const uid = auth.currentUser.uid;
    const brandRef = doc(db, 'users', uid);
    const updatedBrandData = {
      ...brandData,
      styleTypes: selectedStyles.map(
        (id) => styleOptions.find((option) => option.id === id).label
      ),
    };

    try {
      setLoading(true);
      await setDoc(brandRef, { brandData: updatedBrandData }, { merge: true });

      console.log('Brand data saved successfully');

      const response = await axios.post(process.env.REACT_APP_GENERATE_IDEAS, updatedBrandData);
      console.log('Brand data sent to backend:', response.data);

      const ideas = response.data.ideas;

      await setDoc(brandRef, { ideas }, { merge: true });

      console.log('Ideas saved to Firestore:', ideas);

      navigate('/', { state: { ideas } });
    } catch (error) {
      console.error('Error in processing', error);
      setError('Failed to process your request. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
        padding: isMobile ? '300px 20px 20px 20px' : '20px 15px 30px',
        overflow: 'auto',
      }}
    >
      <IconButton
        aria-label="back"
        onClick={() => navigate(-1)}
        style={{
          position: 'absolute',
          top: '20px',
          left: '20px',
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <Typography 
        variant={isMobile ? 'h6' : 'h5'} 
        style={{ 
          fontWeight: 'bold', 
          alignSelf: 'center', 
          marginTop: isMobile ? '80px' : '80px', 
          marginBottom: '20px', 
          textAlign: 'center',
          padding: isMobile ? '0 10px' : '0',
        }}
      >
        Choose preferred styles
      </Typography>

      <Grid container spacing={2} justifyContent="center" style={{ maxWidth: isMobile ? '100%' : '50%' }}>
        {styleOptions.map((style) => (
          <Grid item xs={6} sm={4} md={3} key={style.id}>
            <Box
              onClick={() => handleStyleSelect(style.id)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '10px',
                border: selectedStyles.includes(style.id) ? '2px solid #FF001B' : '2px solid #f5f5f5',
                overflow: 'hidden',
                cursor: 'pointer',
                mb: 2,
                width: '100%',
                height: isMobile ? '150px' : '200px',
                position: 'relative',
                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                },
              }}
            >
              <img src={style.image} alt={style.label} />
              <Typography
                variant="subtitle2"
                align="center"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  padding: '5px 0',
                }}
              >
                {style.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {error && (
        <Typography color="error" style={{ marginTop: '20px' }}>
          {error}
        </Typography>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '1000px',
          marginTop: '30px',
        }}
      >
        <IconButton
          aria-label="next"
          onClick={saveBrandDataToFirestore}
          disabled={loading}
          style={{
            backgroundColor: '#FF001B',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#e2400f' },
            '&:disabled': { backgroundColor: '#cccccc' },
            borderRadius: '10px',
            width: '50px',
            height: '50px',
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : <ArrowForwardRoundedIcon />}
        </IconButton>
      </div>
    </div>
  );
}

export default Preferences;
