import React, { useState } from 'react';
import BrandDataInput from '../components/SignUp_Flow/BrandData';
import Preferences from '../components/SignUp_Flow/Preferences';

function Welcome() {
  const [step, setStep] = useState(1);
  const [brandData, setBrandData] = useState({});

  const handleNext = (data) => {
    if (step === 1) {
      setBrandData(data);
      setStep(2);
    } else {
      // Handle final submission if needed
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {step === 1 ? (
        <BrandDataInput onNext={handleNext} />
      ) : (
        <Preferences brandData={brandData} onNext={handleNext} />
      )}
    </div>
  );
}

export default Welcome;
