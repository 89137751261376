import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, Snackbar, useMediaQuery } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase'; // Make sure to have your firebase configuration in a separate file
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { Google as GoogleIcon } from '@mui/icons-material';

const LoginGenerate = ({ setUser }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user) {
        const uid = user.uid;
        const userRef = doc(db, "users", uid);
        const userSnapshot = await getDoc(userRef);
        const email = user.email;

        if (!userSnapshot.exists() || !userSnapshot.data()) {
          navigate('/welcome', { state: { signupSuccess: true, email } });
        } else {
          console.log('User logged in with Google successfully');
          setUser(user);
        }
      }
    } catch (error) {
      console.error('Error signing in with Google:', error.message);
      setErrorMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });

    return () => unsubscribe();
  }, [setUser]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={isMobile ? "100vh" : "20vh"} // Adjusted height
      width="100%"
      padding={isMobile ? '20px' : '200px'}
    >
      <Box textAlign="center">
        <Typography variant={isMobile ? "h4" : "h1"} sx={{ fontWeight: 'bold', marginBottom: isMobile ? '10px' : '40px' }}>
          Imagine. Create. Buy.
        </Typography>
        <Typography variant={isMobile ? "h6" : "h5"} sx={{ marginBottom: isMobile ? '10px' : '30px' }}>
          The anything store. Create unique products with a line of text.
        </Typography>
        <Button
          variant="outlined"
          onClick={handleGoogleSignIn}
          startIcon={<GoogleIcon />}
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
            width: isMobile ? '100%' : '300px',
            minHeight: '50px',
            color: '#FF001B',
            marginTop: isMobile ? '10px' : '20px',
            borderColor: '#FF001B'
          }}
        >
          Start creating
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        key={'topcenter'}
      />
    </Box>
  );
};

export default LoginGenerate;
