import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const CustomAccordion = styled(Accordion)({
  marginTop: '10px',
  padding: '5px',
  backgroundColor: 'transparent',
  border: '1px solid #CBCBCB',
  color: '#717171',
  boxShadow: 'none',
  borderRadius: '4px',
});

const CustomTableContainer = styled(TableContainer)({
  boxShadow: 'none',
  borderRadius: '0px',
  width: '100%',
  overflowX: 'auto',
});

const StyledTableCell = styled(TableCell)({
  padding: '8px',
  fontSize: '14px',
  textAlign: 'center',
  '@media (max-width:600px)': {
    padding: '4px',
    fontSize: '12px',
  },
});

function createData(size, widthInch, widthCm, lengthInch, lengthCm, sleeveInch, sleeveCm) {
  return { size, widthInch, widthCm, lengthInch, lengthCm, sleeveInch, sleeveCm };
}

const rows = [
  createData('S', 18.25, 46.3, 26.62, 67.6, 16.25, 41.3),
  createData('M', 20.25, 51.4, 28, 71.1, 17.75, 45.1),
  createData('L', 22, 55.9, 29.37, 74.6, 19, 48.3),
  createData('XL', 24, 61, 30.75, 78.1, 20.5, 52.1),
  createData('2XL', 26, 66, 31.62, 80.3, 21.75, 55.2),
  createData('3XL', 27.75, 70.5, 32.5, 82.5, 23.25, 59),
  createData('4XL', 29.75, 75.5, 33.5, 85.1, 24.62, 62.5),
];

export default function TShirtSizeChart({ loading }) {
  return (
    <div>
      <CustomAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          Size Chart
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '4px' }} />
          ) : (
            <CustomTableContainer component={Paper}>
              <Table aria-label="size chart table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Size</StyledTableCell>
                    <StyledTableCell align="center">Width (Inch)</StyledTableCell>
                    <StyledTableCell align="center">Width (Cm)</StyledTableCell>
                    <StyledTableCell align="center">Length (Inch)</StyledTableCell>
                    <StyledTableCell align="center">Length (Cm)</StyledTableCell>
                    <StyledTableCell align="center">Sleeve Center Back (Inch)</StyledTableCell>
                    <StyledTableCell align="center">Sleeve Center Back (Cm)</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.size}>
                      <StyledTableCell component="th" scope="row">
                        {row.size}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.widthInch}</StyledTableCell>
                      <StyledTableCell align="center">{row.widthCm}</StyledTableCell>
                      <StyledTableCell align="center">{row.lengthInch}</StyledTableCell>
                      <StyledTableCell align="center">{row.lengthCm}</StyledTableCell>
                      <StyledTableCell align="center">{row.sleeveInch}</StyledTableCell>
                      <StyledTableCell align="center">{row.sleeveCm}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
          )}
        </AccordionDetails>
      </CustomAccordion>
    </div>
  );
}
