import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import GeneratingComponent from '../components/Landing/GeneratingComponent';
import GenerateHeader from '../components/Headers/GenerateHeader';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

const Generate = () => {
  const location = useLocation();
  const [loadingState, setLoadingState] = useState(location.state?.loadingState || false);
  const [designs, setDesigns] = useState([]);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(location.state?.inputValue || '');
  const [userId, setUserId] = useState(location.state?.userId || '');
  const [brandData, setBrandData] = useState(null);
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        const userDoc = doc(firestore, 'users', user.uid);
        const userSnap = await getDoc(userDoc);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log('User data:', userData);
          setBrandData(userData.brandData || {});
        } else {
          console.error('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchUserData(user);
      } else {
        console.error('No user is logged in');
        navigate('/login'); // Redirect to login if no user is logged in
      }
    });

    return () => unsubscribe();
  }, [auth, firestore, navigate]);

  useEffect(() => {
    if (brandData !== null && loadingState) {
      console.log('Brand data is set, fetching designs...');
      fetchDesigns();
    }
  }, [brandData, loadingState]);

  const handleBackClick = () => {
    navigate('/');
  };

  const fetchDesigns = async () => {
    console.log('Starting fetchDesigns...');
    if (brandData === null) {
      console.error('Brand data is null, cannot proceed with fetching designs.');
      return;
    }
    try {
      const postData = {
        prompt: inputValue,
        uid: userId,
        brandData: brandData || {},  // Ensure brandData is an empty object if null
      };
      console.log('Sending request to REACT_APP_INTERPRET_PROMPT_AND_GENERATE_DESIGN with data:', postData);

      const response = await axios.post(process.env.REACT_APP_INTERPRET_PROMPT_AND_GENERATE_DESIGN, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Received response from REACT_APP_INTERPRET_PROMPT_AND_GENERATE_DESIGN:', response.data);

      if (response.data && response.data.status === 'completed' && response.data.data) {
        const responseData = response.data.data;
        console.log('Response data:', responseData);

        if (!responseData.design_urls) {
          throw new Error('Design URLs not found in response data');
        }

        const removeBackgroundData = {
          design_urls: responseData.design_urls,
        };

        console.log('Sending request to REACT_APP_REMOVE_BACKGROUND with data:', removeBackgroundData);

        const removeBackgroundResponse = await axios.post(process.env.REACT_APP_REMOVE_BACKGROUND, removeBackgroundData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log('Received response from REACT_APP_REMOVE_BACKGROUND:', removeBackgroundResponse.data);

        if (removeBackgroundResponse.data && removeBackgroundResponse.data.status === 'completed' && removeBackgroundResponse.data.design_urls) {
          const finalDesignUrls = removeBackgroundResponse.data.design_urls;

          const generateProductData = {
            uid: userId,
            extracted_data: responseData.extracted_data,
            design_urls: finalDesignUrls,
            prompt: inputValue,
          };

          console.log('Sending request to REACT_APP_GENERATE_PRODUCT with data:', generateProductData);

          const generateProductResponse = await axios.post(process.env.REACT_APP_GENERATE_PRODUCT, generateProductData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          console.log('Received response from REACT_APP_GENERATE_PRODUCT:', generateProductResponse.data);

          if (generateProductResponse.data) {
            const nameorData = {
              products: generateProductResponse.data.products.map(product => ({
                ...product,
                uid: userId,
                tags: Array.isArray(product.tags) ? product.tags.join(', ') : (product.tags || '')
              })),
              uid: userId
            };

            console.log('Sending request to REACT_APP_NAMEOR_V2 with data:', nameorData);

            const nameorResponse = await axios.post(process.env.REACT_APP_NAMEOR_V2, nameorData, {
              headers: {
                'Content-Type': 'application/json',
              },
            });

            console.log('Received response from REACT_APP_NAMEOR_V2:', nameorResponse.data);

            if (nameorResponse.data) {
              console.log('Sending product data to upload endpoint');
              await axios.post(process.env.REACT_APP_UPLOAD_PRODUCT, { products: nameorResponse.data.products.map(product => ({
                ...product,
                tags: Array.isArray(product.tags) ? product.tags.join(', ') : (product.tags || '')
              })), uid: userId }, {
                headers: {
                  'Content-Type': 'application/json',
                },
              });

              console.log('Successfully uploaded product data');

              setDesigns(nameorResponse.data.products);
            } else {
              throw new Error('Invalid response structure from REACT_APP_NAMEOR_V2');
            }

            console.log('Successfully sent data to REACT_APP_NAMEOR_V2');
          } else {
            throw new Error('Invalid response structure from REACT_APP_GENERATE_PRODUCT');
          }
        } else {
          throw new Error('Invalid response structure from REACT_APP_REMOVE_BACKGROUND');
        }
      } else {
        throw new Error('Invalid response structure from REACT_APP_INTERPRET_PROMPT_AND_GENERATE_DESIGN');
      }
    } catch (error) {
      console.error('Error in fetchDesigns:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
      }
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <>
      <GenerateHeader 
        setLoadingState={setLoadingState} 
        inputValue={inputValue} 
        setInputValue={setInputValue}
        userId={userId}
        setUserId={setUserId}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
        sx={{ overflow: 'hidden' }}
      >
        <IconButton
          onClick={handleBackClick}
          sx={{
            position: 'fixed',
            left: { xs: '10px', md: '50px' },
            top: { xs: '10px', md: '50px' },
            zIndex: 10,
          }}
        >
          <ArrowBack />
        </IconButton>
        <GeneratingComponent loading={loadingState} setLoading={setLoadingState} designs={designs} />
      </Box>
    </>
  );
};

export default Generate;
